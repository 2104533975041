<template>
  <div class="register">
    <b-container id="form">
      <b-row>
        <b-col cols="12">
          <b-form @submit="onSubmit">
            <b-row class="form-row">
              <b-col>
                <b-form-group
                  id="input-first-name"
                  label="Vorname:"
                  label-for="input-first-name"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                  data-validation-text="Bitte ausfüllen"
                >
                  <b-col>
                    <b-form-input
                      id="first-name"
                      size="sm"
                      v-model="form.first_name"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-last-name"
                  label="Nachname:"
                  label-for="input-last-name"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col>
                    <b-form-input
                      id="last-name"
                      size="sm"
                      v-model="form.last_name"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-street"
                  label="Straße:"
                  label-for="street"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="street"
                      size="sm"
                      v-model="form.street"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus."
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-street_number"
                  label="Hausnummer:"
                  label-for="street_number"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="street_number"
                      size="sm"
                      v-model="form.street_number"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus."
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-zipcode"
                  label="Postleitzahl:"
                  label-for="zipcode"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="zipcode"
                      size="sm"
                      v-model="form.zipcode"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus."
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-town"
                  label="Stadt:"
                  label-for="town"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="town"
                      size="sm"
                      v-model="form.town"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte füllen Sie dieses Feld aus."
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  id="input-account"
                  label="Ihr @muenster.de-Hauptaccount:"
                  label-for="input-account"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <div class="input-group-append">
                      <b-form-input
                        id="account"
                        size="sm"
                        v-model="form.account"
                        required
                        :disabled="form_disabled"
                        aria-describedby="mail-feedback"
                        trim
                        data-validation-text="Bitte füllen Sie dieses Feld aus"
                      ></b-form-input>
                      <span
                        class="input-group-text form-control-sm"
                        style="font-size: 0.875rem"
                        id="basic-addon2"
                        >@muenster.de</span
                      >
                    </div>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-mail"
                  label="Gewünschter E-Mail-Alias:"
                  label-for="input-alias"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <div class="input-group-append">
                      <b-form-input
                        id="alias"
                        size="sm"
                        v-model="form.alias"
                        required
                        :disabled="form_disabled"
                        aria-describedby="mail-feedback"
                        :state="mailState"
                        trim
                        data-validation-text="Bitte füllen Sie dieses Feld aus"
                      ></b-form-input>
                      <span
                        class="input-group-text form-control-sm"
                        style="font-size: 0.875rem"
                        id="basic-addon2"
                        >@muenster.de</span
                      >
                    </div>
                  </b-col>
                  <b-col>
                    <b-form-text
                      id="mail-info"
                      v-show="this.mailState != null && !this.mailState"
                      >Der gewünschte E-Mail-Alias @muenster.de muss mindestens
                      drei Zeichen lang sein und mindestens drei Buchstaben
                      enthalten</b-form-text
                    >
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="input-notes"
                  label="Begründung:"
                  label-for="input-notes"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <div class="input-group-append">
                      <b-form-textarea
                        id="notes"
                        size="sm"
                        v-model="form.notes"
                        rows="8"
                        required
                        :disabled="form_disabled"
                      ></b-form-textarea>
                    </div>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="this.form.captcha_id != ''">
              <b-col>
                <img :src="get_image_captcha_url()" />
              </b-col>
            </b-row>
            <b-row v-if="this.form.captcha_id != ''">
              <b-col>
                <audio
                  id="audio_with_controls"
                  controls
                  :src="get_audio_captcha_url()"
                  type="audio/mp3"
                >
                  Ihr Browser kann dieses Tondokument nicht wiedergeben.<br />
                </audio>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <img src="" />
                <b-form-group
                  id="input-captcha"
                  label="Captcha:"
                  label-for="input-captcha"
                  label-cols="4"
                  label-cols-md="3"
                  label-align="right"
                >
                  <b-col cols="12" md="12">
                    <b-form-input
                      id="phone"
                      size="sm"
                      v-model="form.captcha_solution"
                      required
                      :disabled="form_disabled"
                      data-validation-text="Bitte geben Sie die Zahlenfolge ein."
                      pattern="[0-9].+"
                    ></b-form-input>
                  </b-col>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-checkbox
                  id="agreement"
                  v-model="form.agreement"
                  name="agreement"
                  required
                  :disabled="form_disabled"
                  data-validation-text="Bitte akzeptieren Sie die Nutzungsbedingungen."
                >
                  <b-col>
                    Ich akzeptiere die
                    <a
                      href="//www.muenster.de/email_nutzungsbedingungen.html"
                      target="_blank"
                      >Nutzungsbedingungen</a
                    >
                    für eine @muenster.de-Adresse.
                  </b-col>
                </b-form-checkbox>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-checkbox
                  id="rules"
                  v-model="form.rules"
                  name="rules"
                  required
                  :disabled="form_disabled"
                  data-validation-text="Bitte akzeptieren Sie die Alias-Bedingungen."
                >
                  <b-col>
                    Ich bestätige, dass der Alias in Verbindung zu meinem
                    Namen steht und dass ich die maximale Anzahl an Adressen noch
                    nicht ausgeschöpft habe. Mir ist bewusst, dass der Alias
                    nicht angelegt wird und ich keine Rückmeldung erhalte, wenn
                    dies nicht der Fall ist.
                  </b-col>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col>
                <b-alert v-model="msg_show" v-bind:variant="msg_type">
                  <p style="font-weight: bold">{{ msg_text }}</p>
                </b-alert>
              </b-col>
            </b-row>
            <b-row v-if="!form_disabled">
              <b-col>
                <b-button
                  type="submit"
                  size="sm"
                  variant="primary"
                  :disabled="send_disabled"
                  >Antrag abschicken</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Register",
  data() {
    return {
      form: {
        alias: "",
        account: "",
        notes: "",
        captcha_id: "",
        captcha_solution: "",
        agreement: false,
        rules: false,
      },
      msg_text: "",
      msg_show: false,
      msg_type: "",
      form_disabled: false,
      send_disabled: true,
      form_send: false,
    };
  },
  mounted() {
    this.get_captcha_id();
    this.check_form();
    var elements = document.getElementsByTagName("INPUT");
    for (var i = 0; i < elements.length; i++) {
      elements[i].oninvalid = function (e) {
        e.target.setCustomValidity("");
        if (!e.target.validity.valid) {
          e.target.setCustomValidity(e.target.dataset.validationText);
        }
      };
      elements[i].oninput = function (e) {
        e.target.setCustomValidity("");
      };
    }
  },
  watch: {
    "form.alias": function () {
      this.check_form();
    },
    "form.account": function () {
      this.check_form();
    },
    "form.agreement": function () {
      this.check_form();
    },
    "form.notes": function () {
      this.check_form();
    },
    "form.rules": function () {
      this.check_form();
    },
  },

  computed: {
    mailState() {
      if (this.form.alias.length == 0) {
        return null;
      }
      var letters = this.form.alias.replace(/[^a-zA-Z]/gi, "");
      if (letters.length < 3) {
        return false;
      }
      return this.form.alias.length >= 3 ? true : false;
    },
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      axios
        .post("/api/alias", JSON.stringify(this.form))
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.msg_type = "success";
          this.msg_text =
            "Die Daten wurden erfolgreich übermittelt. Bitte bestätigen Sie die E-Mail in Ihrem @muenster.de-Postfach ";
          this.msg_show = true;
          this.form_disabled = true;
          this.send_disabled = true;
          this.form_send = true;
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          if (error != null) {
            this.msg_type = "warning";
            this.msg_show = true;
            this.msg_text = error.response.data;
            this.get_captcha_id();
            this.form.captcha_solution = "";
          }
          //console.log(error);
        });
    },
    update_streets() {
      if (this.form.street.length < 3) return;

      if (this.form.street.length == 3) {
        var prefix = this.form.street.substring(0, 3);
        if (this.street_prefix != prefix) {
          this.street_prefix = prefix;
          axios
            .get("/api/streets/" + this.form.street)
            .then((response) => (this.options.streets = response.data));
        }
      }
      if (this.form.street.length > 3) {
        axios
          .get("/api/street_numbers/" + this.form.street)
          .then((response) => (this.options.street_numbers = response.data));
      }
    },
    get_captcha_id() {
      axios
        .get("/api/captcha")
        .then((response) => (this.form.captcha_id = response.data));
    },
    get_image_captcha_url() {
      return "/api/captcha/" + this.form.captcha_id + ".png";
    },
    get_audio_captcha_url() {
      return "/api/captcha/" + this.form.captcha_id + ".wav";
    },
    check_form() {
      if (this.form_send) {
        return;
      }
      var text = "Bitte ergänzen Sie folgende Angaben: ";
      var forms = [];
      if (this.form.alias == "" || !this.mailState) {
        forms.push("Gewünschte E-Mail-Alias");
      }
      if (this.form.account == "") {
        forms.push("vorhandener E-Mail-Account");
      }
      if (!this.form.notes) {
        forms.push("Begründung");
      }
      if (!this.form.agreement) {
        forms.push("Nutzungsbedingungen");
      }
      if (!this.form.rules) {
        forms.push("Alias-Regeln");
      }
      if (this.form.notes != "" && this.form.url == "") {
        forms.push("Begründung");
      }

      if (forms.length > 0) {
        this.msg_show = true;
        this.msg_type = "info";
        text += forms.join(", ");
        this.msg_text = text;
        this.send_disabled = true;
      } else {
        this.send_disabled = false;
        this.msg_show = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
a {
  color: #005b79;
  text-decoration: underline;
}
.form {
  margin: 40px;
}
.info p {
  text-align: left;
}

.modal-dialog {
  max-width: 80%;
}

.btn-primary {
  background-color: #005b79;
  border-color: #005b79;
}

h4 {
  font-size: 1.1rem;
}

.register {
  padding-bottom: 50px;
  margin-bottom: 50px;
}

#mail-info {
  color: red !important;
  font-size: 1em;
}

#input-account {
  font-weight: bold;
}
</style>
